import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PcIndex',
    component: () => import('../views/pc/index.vue')
  },
  {
    path: '/h5',
    name: 'H5Index',
    component: () => import('../views/h5/index.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 移动端
  let isPc = !/Mobi|Android|iPhone/i.test(navigator.userAgent)
  if (isPc) {
    if (to.path == '/') {
      next()
    } else {
      next('/')
    }
  } else {
    if (to.path == '/h5') {
      next()
    } else {
      next('/h5')
    }
  }
})


export default router
